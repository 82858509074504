import { useNavigate } from 'react-router-dom';
import { CourseCardComponent, CourseImage, CourseInfo } from './training.styles';
import CloudImage from '../../Cloudinary/Photos/photos.component';
import TertiaryButton from '../../Components/Buttons/tertiaryButton.component';

const CourseCardSingle = ({c}) => {

    const navigate = useNavigate();
    const learnMore = (e, id) => {
        e.preventDefault();
        navigate(`/course/${id}`)
    }
    console.log('course card',c);

    return ( 
        <CourseCardComponent className='boxShadow' key={c?._id}>
                    <CourseImage>
                        <CloudImage publicId={c?.image?.publicId}/>
                    </CourseImage>
                    <CourseInfo>
                        <div className='topContainer'>
                        <h4>{c?.courseName}</h4>
                        <p>{c?.description.length <= 100 ? c?.description : c?.description.slice(0, 100) + ' ...'}</p>
                        </div>
                        <div className='buttonContainer'>
                            <TertiaryButton functionName={(e) => learnMore(e, c?._id)} span="Learn more" />
                        </div>
                    </CourseInfo>
                </CourseCardComponent>
     );
}
 
export default CourseCardSingle;