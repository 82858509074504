import PrimaryButton from "../../../Components/Buttons/primaryButton.component";
import { httpCreateCourse, httpCreateFiles } from "../../../Hooks/admin.hooks";

// Submit Function for Handling the course Submit
const CreateCourseSubmit = ({questions, pdf, notes, videoLinks, documentation, 
    image, expiryDate, certificatePrefix, courseName, tags, description, price, setSuccessState, setIsOpen}) => {
   
    const handleSubmit = async(e) => {
        e.preventDefault();
        console.log('PDF', pdf);
        console.log('image', image);
        //const formData = new FormData();
        // formData.append('courseName', courseName);
        // formData.append('price', price);
        // formData.append('description', description);
        // formData.append('tags', tags);
        //formData.append('pdf', pdf);
        // formData.append('notes', notes);
        // formData.append('videoLinks', videoLinks);
        // formData.append('documentation', documentation);
        //formData.append('image', image);
        // formData.append('quiz', questions);
        // formData.append('expiryDate', expiryDate);
        // formData.append('certificatePrefix', certificatePrefix);
        // for (let [key, value] of formData.entries()) {
        //     console.log(`${key}: ${value}`);
        // }
        //console.log('SENDING OUT', pdf, image);
        //let res1 = await httpCreateFiles(formData);
        //let pdfName = pdf.name;
        //let imgName = image.name;

        const fullCourse = Object.assign({}, {
            courseName: courseName,
            price: price,
            description: description,
            tags: tags,
            pdf: pdf,
            notes: notes,
            videoLinks: videoLinks,
            documentation: documentation,
            image: image,
            quiz: questions,
            expiryDate: expiryDate,
            certificatePrefix: certificatePrefix
        })
        
        //console.log('result 1',res1)
        let res = await httpCreateCourse(fullCourse);
        let data = await res.json();
        if (res.ok) {       
            setSuccessState('Course has been created');
            setIsOpen(true)
           } else {
            setSuccessState(data.msg);
            setIsOpen(true)
        }
    }
    return (
     <>
     <PrimaryButton functionName={(e) => handleSubmit(e)} span="Create Course" />
    </> 
    );
}
 
export default CreateCourseSubmit;