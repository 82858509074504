import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PasswordInput from "../authComponents.jsx/PasswordInput";
import PrimaryButton from "../../Components/Buttons/primaryButton.component";
import {httpResetPassword} from '../../Hooks/users.hooks';
import { AuthContainer, AuthContainer2, AuthenticationSide } from "../auth.styles";
const ResetPassword = () => {
    
    const navigate = useNavigate();
    const {token} = useParams();
    const [pass, setPass] = useState('');
    const [showPass, setShowPass] = useState(false);
    const [verifyPass, setVerifyPass] = useState('');
    const [verifyShowPass, setVerifyShowPass] = useState(false);
    const [err, setErr] = useState('');
    const [success, setSuccess] = useState('');

    const handleChangePassword = (e) => {
        setPass(e.target.value);
    }
    const handleChangeVerifyPassword = (e) => {
        setVerifyPass(e.target.value);
    }
    const handleSubmit = async(e) => {
        setSuccess('');
        setErr('');
        e.preventDefault();
        if(pass !== verifyPass){
            setErr('Passwords do not match');
            return;
        }
        try{
            const res = await httpResetPassword(token, pass);
            const data = res.json();
            if(res.ok){
            setSuccess('Password has been reset');
            setTimeout(() => {
                navigate('/login')
            }, 5000);
            } else {
            setErr(data.msg)
         }
        } catch (err){
            setErr('An error has occured')
        }
    }

    return ( 
        <>
        <AuthContainer style={{width: '100%'}}>
            <AuthContainer2 style={{width: '100%'}}>
            <AuthenticationSide className="boxShadow" style={{alignItems:'start'}}>
        <h2 style={{marginLeft: '1rem'}}>Reset Password</h2>
        <form>
        {/* Password */}
        <PasswordInput 
                label="Password" 
                span="Must be at least 6 characters" 
                handleChange={(e) => {
                    e.preventDefault();
                    setShowPass(prev => !prev)}}
                showPass={showPass}
                value={pass}
                name='pass'
                onChange={handleChangePassword}
            />
        {/* Verify Password */}
        <PasswordInput 
            label="Verify Password"
            handleChange={(e) => {
                e.preventDefault();
                setVerifyShowPass(prev => !prev)}}
            value={verifyPass}
            onChange={handleChangeVerifyPassword}
            name='verifyPass' 
            showPass={verifyShowPass}
            />
        {err && <p style={{color: 'red'}}>{err}</p>}
        {success && <p style={{color: 'green'}}>{success}</p>}
        <PrimaryButton functionName={handleSubmit} span="Reset Password" />
        </form>
        </AuthenticationSide>
        </AuthContainer2>
        </AuthContainer>
        </>
     );
}
 
export default ResetPassword;