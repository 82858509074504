import Auth from '../Helpers/auth';

const API_URL = process.env.REACT_APP_BACKEND_URL;
const token = Auth.getToken();

// GET: Fetches a company - protected route
async function httpGetCompany(id){
    try{
       let company = await fetch(`${API_URL}/companies/${id}`, {
        headers: {
          'Authorization': token ? `Bearer ${token}` : ''
        }
       });
        return company.json();
    } catch(err){
        return err;
    }
}

// PUT: Disables a company - protected route
async function httpDisableCompany(id){
    return await fetch(`${API_URL}/companies/disable-company/${id}`, {
        method: 'put',
        headers: {
          "Content-Type": "application/json",
          'Authorization': token ? `Bearer ${token}` : ''
        },
      })
}

// PUT: Activates a company - protected route
async function httpActivateCompany(id){
    return await fetch(`${API_URL}/companies/activate-company/${id}`, {
        method: 'put',
        headers: {
          "Content-Type": "application/json",
          'Authorization': token ? `Bearer ${token}` : ''
        },
      })
}

export {
   httpGetCompany,
   httpActivateCompany,
   httpDisableCompany
}