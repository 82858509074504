import { httpCreateCompany } from "../../../Hooks/admin.hooks";
import { formatNum } from "../../../Helpers/formatPhoneNumber";
import PrimaryButton from "../../../Components/Buttons/primaryButton.component";

// Button Functionality for Creating a company
const CreateCompanySubmit = ({company, image, setResult, notes, setIsOpen}) => {

const handleSubmit = async(e) => {
        e.preventDefault();
        const {phoneMobile, phoneOffice} = company;
        const companyPhone = await formatNum(phoneOffice);
        const mobilephone = await formatNum(phoneMobile);
        const newCompany = {
            ...company,
            employees: [],
            active: true,
            notes: notes,
            logo: image,
            phoneMobile: mobilephone,
            phoneOffice: companyPhone
        };
        const res = await httpCreateCompany(newCompany);
        const data = await res.json();
        if(res.ok){
            setResult('Company has been created');
            setIsOpen(true)
        } else {
            setResult(data.msg);
            setIsOpen(true)
        }
    }

    return (  
        <>
        <PrimaryButton functionName={(e) => handleSubmit(e)} span="Create Company" />
        </>
    );
}
 
export default CreateCompanySubmit;