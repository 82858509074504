import SecondaryButton from "../../../Components/Buttons/secondaryButton.component";
import { httpDisableCompany } from "../../../Hooks/companies.hooks";

// Deactivates a company
const DeactivateCompanyButton = ({id, setSuccessState, setIsOpen}) => {

    const editCompany = async(e) => {
        e.preventDefault();
        let res = await httpDisableCompany(id);
        let data = await res.json();
        if(res.ok){
            setSuccessState('Company has been deactivated');
            setIsOpen(true)
        } else {
            setSuccessState(data.msg);
            setIsOpen(true)
        }
    }

    return (  
        <>
        <SecondaryButton functionName={editCompany} span="Deactivate Company" />
        </>
    );
}
 
export default DeactivateCompanyButton;