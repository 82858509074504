import { httpDisableUser } from "../../../Hooks/users.hooks";
import SecondaryButton from "../../../Components/Buttons/secondaryButton.component";

// Disbales a user
const DisableUserButton = ({setSuccessState, setIsOpen, id}) => {

    const handleDelete = async(e) => {
        e.preventDefault();
        let res = await httpDisableUser(id);
        let data = await res.json();
        if(res.ok) {       
            setSuccessState('Users account has been disabled');
            setIsOpen(true)
           } else {
            setSuccessState(data.msg);
            setIsOpen(true)
        }
    }

    return ( 
        <>
        <SecondaryButton functionName={handleDelete} span="Disable User" />
        </>
     );
}
 
export default DisableUserButton;