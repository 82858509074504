import SecondaryButton from "../../../Components/Buttons/secondaryButton.component";
import { httpActivateCompany } from "../../../Hooks/companies.hooks";

// Activates a company
const ActivateCompanyButton = ({id, setSuccessState, setIsOpen}) => {

    const activateCompany = async(e) => {
        e.preventDefault();
        let res = await httpActivateCompany(id);
        let data = await res.json();
        if(res.ok){
            setSuccessState('Company has been activated');
            setIsOpen(true)
        } else {
            setSuccessState(data.msg);
            setIsOpen(true)
        }
    }

    return (  
        <>
        <SecondaryButton functionName={activateCompany} span="Activate Company" />
        </>
    );
}
 
export default ActivateCompanyButton;