import { httpDeleteUser } from "../../../Hooks/admin.hooks";


// Deletes a user
const DeleteUserButton = ({setSuccessState, setIsOpen, id}) => {

    const handleDelete = async(e) => {
        e.preventDefault();
        let res = await httpDeleteUser(id);
        let data = await res.json();
        if(res.ok) {       
            setSuccessState('User has been deleted');
            setIsOpen(true)
           } else {
            setSuccessState(data.msg);
            setIsOpen(true)
        }
    }

    return ( 
        <>
        <button className="destructiveButton" onClick={handleDelete}><span>Delete User</span></button>
        </>
     );
}
 
export default DeleteUserButton;