import { useState } from "react";
import PrimaryButton from "../../../Components/Buttons/primaryButton.component";
import SearchInput from "../searchInput.component";
import { InsideSearch } from "../admin.styles";
const SearchCompanies = ({companies, setFiltered, setErr}) => {
    const [search, setSearch] = useState('');
    const setSearchCompanies = (e) => {
        e.preventDefault();
        setErr('');
        const searchResults = companies.filter(c => c.companyName.toLowerCase().includes(search.toLowerCase()));
        if(!searchResults.length){
            setErr('No companies found');
        }
        setFiltered(searchResults);
    }
    return ( 
        <InsideSearch>
        <SearchInput value={search} name="companies" setValue={setSearch} />
        <PrimaryButton functionName={setSearchCompanies} span="search"/>
        </InsideSearch>
     );
}
 
export default SearchCompanies;