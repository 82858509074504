import { httpDeleteCompany } from "../../../Hooks/admin.hooks";

// Deletes a company
const DeleteCompanyButton = ({id, setSuccessState, setIsOpen}) => {

    const deleteCompany = async(e) => {
        e.preventDefault();
        let res = await httpDeleteCompany(id);
        let data = await res.json();
        if(res.ok){
            setSuccessState('Company has been deleted');
            setIsOpen(true)
        } else {
            setSuccessState(data.msg);
            setIsOpen(true)
        }
    }

    return (  
        <>
        <button className="destructiveButton" onClick={deleteCompany}><span>Delete Company</span></button>
        </>
    );
}
 
export default DeleteCompanyButton;
