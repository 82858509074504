import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {ThemeProvider} from 'styled-components';
import { theme } from './Visuals/Colors';
import React from 'react';
//Pages
import NavBar from './Routes/MainComponents/navigation/navbar.component';
import Footer from './Routes/MainComponents/footer/footer.component';
import BasePage from './Routes/MainComponents/base/base.component';
import AboutUs from './Routes/AboutUs/about.component';
import ProgramDevelopment from './Routes/ProgramDevelopment/program.component';
import Insurance from './Routes/Insurances/insurance.component';
import Resources from './Routes/Resources/resource.component';
import WCBCertified from './Routes/WcbCertified/wcb.component';
import OnlineTraining from './Routes/Online/training-online.component';
import ClassroomTraining from './Routes/Classroom/training-classroom.component';
import NotFound from './Routes/MainComponents/notFound/notFound.component';

// Authentication
import AuthComponent from './Authentication/authentication.component';
import AuthBase from './Authentication/auth-base.component';
import ForgotPassword from './Authentication/PasswordReset/forgot-password.component';
import ResetPassword from './Authentication/PasswordReset/reset-password.component';
import Unauthorized from './Unauthorized/unauthorized.component';

//Admin:
import AdminBase from './AdminRoutes/AdminBase/base-admin.component';
import Admin from './AdminRoutes/Admin/admin.component';
import CreateUser from './AdminRoutes/CreateRoutes/CreateUser/user-create.component';
import CreateCompany from './AdminRoutes/CreateRoutes/CreateCompany/company-create.component';
import CreateCourse from './AdminRoutes/CreateRoutes/CreateCourse/course-create.component';
import EditCourse from './AdminRoutes/EditRoutes/EditCourse/course-edit.component';
import EditUser from './AdminRoutes/EditRoutes/EditUser/user-edit.component';
import EditCompany from './AdminRoutes/EditRoutes/EditCompany/company-edit.component';

//User:
import UserBase from './UserRoutes/UserBase/base-user.component';
import UserHome from './UserRoutes/UserHomepage/userHome.component';
import UserSettings from './UserRoutes/UserSettings/userSettings.component';
import MobileCourses from './UserRoutes/UserMobile/MobileCourses.component';

//Course:
import CourseBase from './Courses/CourseBase/base-course.component';
//import BuyCourse from './BuyCourse/BuyCoursePage/buyCourse.component';
import QuizComponent from './Quiz/quizHome.component';
import CourseComplete from './Quiz/Completed/quiz-complete.component';
import IncompleteCourse from './Quiz/Incomplete/quiz-incomplete.component';
import CourseHomepage from './TakeCourse/CourseHomepage.component';
import SingleCourse from './Courses/EachCourse.component';
//import AllCourses from './Courses/courseList.component';
import CourseReview from './UserRoutes/UserCourseReview/CourseReview.component';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import {
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query'
//

function App() {
  const queryClient = new QueryClient();
  //const [queryClient] = React.useState(() => new QueryClient())
  return (
    <div className="App">
    <QueryClientProvider client={queryClient}>

       <ThemeProvider theme={theme}>
        <BrowserRouter>
        <NavBar />
          <Routes>
            {/* Main public routes */}
            <Route path='/' element={<BasePage />}> 
            <Route index element={<AboutUs />} /> 
            <Route path='program' element={<ProgramDevelopment />} />
            <Route path='resources' element={<Resources />} />
            <Route path='insurance' element={<Insurance />} /> 
            <Route path='wcb-certified' element={<WCBCertified />} />
            <Route path='online' element={<OnlineTraining />} />  
            <Route path='classroom' element={<ClassroomTraining />} />
            <Route path='login' element={<AuthBase />}>
              <Route index element={<AuthComponent />} />
              <Route path='forgot-password' element={<ForgotPassword />}/>
              <Route path='reset-password/:token' element={<ResetPassword />}/>
            </Route>
            <Route path='unauthorized' element={<Unauthorized />} />
            </Route>
              {/* Admin Routes  */}
              <Route path='admin' element={<AdminBase />}>
              <Route index element={<Admin />} /> 
              <Route path='create-course' element={<CreateCourse />} />
              <Route path='create-company' element={<CreateCompany />}/>
              <Route path='create-user' element={<CreateUser/>}/>
              {/* <Route path='course' element={<AllCourses />} /> */}
              <Route path='edit-user/:id' element={<EditUser />} />
              <Route path='edit-course/:id' element={<EditCourse />} />
              <Route path='edit-company/:id' element={<EditCompany />} />
              </Route>
              {/* User Routes */}
              <Route path='user' element={<UserBase />}>
              <Route index  element={<UserHome />} />
              <Route path='settings/:id' element={<UserSettings />} />
              <Route path='user-courses/:id' element={<MobileCourses/> }/>
              </Route>

              {/* Course Routes */}
              <Route path='course' element={<CourseBase />}>
              <Route index path=':id' element={<SingleCourse />} />
              <Route path='home/:id' element={<CourseHomepage/>}/>
              <Route path='quiz/:id' element={<QuizComponent />}></Route>
               <Route path='quiz/complete/:id' element={<CourseComplete />} />
              <Route path='quiz/incomplete/:id' element={<IncompleteCourse />} /> 
              <Route path='review/:id' element={<CourseReview />} />
              </Route>
             
              {/* <Route path='cart/:id' element={<BuyCourse />} /> */}
            
          <Route path="*" element={<NotFound />} /> 
           </Routes>
        <Footer /> 
        </BrowserRouter>
        </ThemeProvider>
    <ReactQueryDevtools initialIsOpen={false} />

    </QueryClientProvider>

    </div>
  );
}

export default App;
