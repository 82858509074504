import EachComponent from "../../../Components/Entity/each.component";
import { httpGetAllCourses } from "../../../Hooks/courses.hooks";
import { useState, useEffect } from "react";
import SearchCourses from "./searchCourses.component";
// Results for courses tab on Admin Home Page
const CoursesTab = () => {
    const [courses, setCourses] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [err, setErr] = useState('');
  
    useEffect(() => {
      const FetchCourses = async () => {
          const data = await httpGetAllCourses();
          setCourses(data);
          setFiltered(data);
          if(!data) setErr('Error fetching the courses');
      };
      FetchCourses();
      }, []);
   
   
    return ( 
        <section>
                <h2>Courses:</h2>
                <SearchCourses courses={courses} setFiltered={setFiltered} setErr={setErr}/>
                <p>{err}</p>
                {filtered?.map((c) => {
                    return <EachComponent key={c?._id} name={c?.courseName} route={`/admin/edit-course/${c?._id}`} />
                } )} 
            </section>
     );
}
 
export default CoursesTab;