import EachComponent from "../../../Components/Entity/each.component";
import { httpGetAllCompanies } from "../../../Hooks/admin.hooks";
import SearchCompanies from "./searchCompanies.component";
import { useState, useEffect } from "react";

// Results for the companies tab on admin page
const CompaniesTab = () => {
  const [companies, setCompanies] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [err, setErr] = useState('');

  useEffect(() => {
    const FetchCompanies = async () => {
        const data = await httpGetAllCompanies();
        setCompanies(data);
        setFiltered(data);
        if(!data) setErr('Error fetching the companies');
    };
    FetchCompanies();
    }, []);


  return (
    <section>
      <h2>Companies:</h2>
      <SearchCompanies companies={companies} setFiltered={setFiltered} setErr={setErr}/>
      <p>{err}</p>
      {filtered?.map((c) => {
        return (<EachComponent c={c} route={`/admin/edit-company/${c?._id}`} /> );
      })}
    </section>
  );
};

export default CompaniesTab;
