import PrimaryButton from "../../../Components/Buttons/primaryButton.component";
import { httpEditCourse } from "../../../Hooks/admin.hooks";
import { useParams } from "react-router-dom";

// Function for editing the course
const EditCourseSubmit = ({questions, pdf, notes, videoLinks, documentation, 
                         image, expiryDate, certificatePrefix, courseName, tags, 
                             description, price, setSuccessState, setIsOpen}) => {
    const {id} = useParams();
    const handleSubmit = async(e) => {
        e.preventDefault();
        const fullCourse = Object.assign({}, {
            courseName: courseName,
            price: price,
            description: description,
            tags: tags,
            pdf: pdf,
            notes: notes,
            videoLinks: videoLinks,
            documentation: documentation,
            image: image,
            quiz: questions,
            expiryDate: expiryDate,
            certificatePrefix: certificatePrefix,
           // updatedAt: Date.now()
        })
        let res = await httpEditCourse(id, fullCourse);
        let data = res.json();
        if (res.ok) {       
            setSuccessState('Course has been edited');
            setIsOpen(true)
           } else {
            setSuccessState(data.msg);
            setIsOpen(true)
        }
    }

    return (
    <>
    <PrimaryButton functionName={(e) => handleSubmit(e)} span="Edit Course" />
    </>
      );
}
 
export default EditCourseSubmit;