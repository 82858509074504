import PrimaryButton from "../../../Components/Buttons/primaryButton.component";
import { httpEditUser } from "../../../Hooks/admin.hooks";

// Function for handling editing a user
const EditUserSubmit = ({user, password,setIsOpen, setSuccessState}) => {

    const handleSubmit = async(e) => {
        e.preventDefault();
        const editedUser = Object.assign(user,{
            password: password,
            //updatedAt: Date.now()
        });
        let res = await httpEditUser(editedUser._id, editedUser);
        let data = await res.json();
        if(res.ok){
            setSuccessState('User has been edited');
            setIsOpen(true);
        } else {
            setSuccessState(data.msg);
            setIsOpen(true);
        }
    }

    return (  
        <PrimaryButton functionName={(e) => handleSubmit(e)} span="Edit User" />
    );
}
 
export default EditUserSubmit;