import PrimaryButton from "../../../Components/Buttons/primaryButton.component";
import { httpCreateUser } from "../../../Hooks/admin.hooks";

// Handles the submit for creating a user
const CreateUserSubmit = ({user, setIsOpen, setSuccessState}) => {

    const handleSubmit = async(e) => {
        e.preventDefault();
        const newUser = Object.assign(user, {
            type: "User",
            courses: []
        });
        let res = await httpCreateUser(newUser);
        let data = await res.json();
        if(res.ok){
            setSuccessState('User has been created');
            setIsOpen(true);
        } else {
            setSuccessState(data.msg);
            setIsOpen(true);
        }
    }
    return ( 
        <>
        <PrimaryButton functionName={(e) => handleSubmit(e)} span="Create User" />
        </>
     );
}
 
export default CreateUserSubmit;