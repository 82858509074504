import SecondaryButton from "../../../Components/Buttons/secondaryButton.component";
import { httpActivateUser } from "../../../Hooks/users.hooks";

// Activates a user
const ActivateUserButton = ({setSuccessState, setIsOpen, id}) => {

    const handleDelete = async(e) => {
        e.preventDefault();
        let res = await httpActivateUser(id);
        let data = await res.json();
        if(res.ok) {       
            setSuccessState('Users account has been activated');
            setIsOpen(true)
           } else {
            setSuccessState(data.msg);
            setIsOpen(true)
        }
    }

    return ( 
        <>
        <SecondaryButton functionName={handleDelete} span="Activate User" />
        </>
     );
}
 
export default ActivateUserButton;