import { AdminTabs } from "./admin.styles";

// Tabs for selecting companies/courses/users on the admin homepage
const AdminTabsComponent = ({showCompanies, showCourses, setShowCompanies, setShowCourses, setShowUsers, showUsers}) => {
    return (  
        <AdminTabs>
        {/* Companies */}
        <button className={showCompanies ? "tabButtonActive boxShadow" : "tabButton boxShadow"} onClick={(e) => {
            e.preventDefault();
            setShowCompanies(true);
            setShowCourses(false);
            setShowUsers(false);
        }}><span>companies</span></button>
        {/* Users */}
        <button className={showUsers ? "tabButtonActive boxShadow" : "tabButton boxShadow"} onClick={(e) => {
            e.preventDefault();
            setShowCompanies(false);
            setShowCourses(false);
            setShowUsers(true);
        }}><span>users</span></button>
        {/* Courses */}
        <button className={showCourses ? "tabButtonActive boxShadow" : "tabButton boxShadow"} onClick={(e) => {
            e.preventDefault();
            setShowCompanies(false);
            setShowCourses(true);
            setShowUsers(false);
        }}><span>courses</span></button>
        </AdminTabs>
    );
}
 
export default AdminTabsComponent;