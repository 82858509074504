import { httpDeleteCourse } from "../../../Hooks/admin.hooks";

// Deletes a course
const DeleteCourseButton = ({setSuccessState, setIsOpen, id}) => {

    const handleDelete = async(e) => {
        e.preventDefault();
        let res = await httpDeleteCourse(id);
        let data = await res.json();
        if(res.ok) {       
            setSuccessState('Course has been deleted');
            setIsOpen(true)
           } else {
            setSuccessState(data.msg);
            setIsOpen(true)
        }
    }

    return ( 
        <>
        <button className="destructiveButton" onClick={handleDelete}><span>Delete Course</span></button>
        </>
     );
}
 
export default DeleteCourseButton;