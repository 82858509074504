import { useState } from "react";
import PrimaryButton from "../../../Components/Buttons/primaryButton.component";
import SearchInput from "../searchInput.component";
import { InsideSearch } from "../admin.styles";

const SearchCourses = ({courses, setFiltered, setErr}) => {
    const [search, setSearch] = useState('');
    const setSearchCourses = (e) => {
        e.preventDefault();
        setErr('');
        const searchResults = courses.filter(c => c.courseName.toLowerCase().includes(search.toLowerCase()));
        if(!searchResults.length){
            setErr('No courses found');
        }
        setFiltered(searchResults);
    }
    return (  
        <InsideSearch>
        <SearchInput value={search} name="courses" setValue={setSearch} />
        <PrimaryButton functionName={setSearchCourses} span="search"/>
        </InsideSearch>
    );
}
 
export default SearchCourses;