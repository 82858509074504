import { httpGetAllUsers } from "../../../Hooks/admin.hooks";
import EachComponent from "../../../Components/Entity/each.component";
import { useState, useEffect } from "react";
import SearchUsers from "./searchUsers.component";

// Results for Users tab on admin home page
const UsersTab = () => {
    const [users, setUsers] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [err, setErr] = useState('');
  
    useEffect(() => {
      setErr('');
      const FetchUsers = async () => {
          const data = await httpGetAllUsers();
          setUsers(data);
          setFiltered(data);
          if(!data) setErr('Error fetching the users');
      };
      FetchUsers();
      }, []);

  return (
    <section>
      <h2>Users:</h2>
       <SearchUsers users={users} setFiltered={setFiltered} setErr={setErr}/>
      <p>{err}</p>
      {filtered?.map((c) => {
        return (
          <EachComponent
            key={c?._id}
            name={c?.firstName + " " + c?.lastName}
            route={`/admin/edit-user/${c?._id}`}
          />
        );
      })}
    </section>
  );
};

export default UsersTab;
