import { useState, useEffect } from "react";
import InputDiv from "../../FormElements/InputText";
import CreateUserSubmit from "./user-create-submit";
import Modal from "../../../Components/Modal/modal.component";
import { useNavigate } from "react-router-dom";
import { FormContainer, SubmitContainer } from "../CreateForm.styles";
import PrimaryButton from "../../../Components/Buttons/primaryButton.component";
import Auth from '../../../Helpers/auth';

// Creating a user form
const CreateUser = () => {
    const navigate = useNavigate();

    const {data} = Auth.getProfile();
    
    useEffect(() => {
        if (!Auth.loggedIn()) {
            navigate('/login'); 
        } else if (data.type !== 'Admin'){
            navigate('/unauthorized')
        }
    }, [navigate, data]);

    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
    });
    const [isOpen, setIsOpen] = useState(false);
    const [successState, setSuccessState] = useState('');

    return (  
        <FormContainer>
        <h2>Create User</h2>
        <hr />
        <form>
        <InputDiv title="First Name" value={user.firstName} name="firstName" nestedValue={setUser} upperValue={user} />
        <InputDiv title="Last Name" value={user.lastName} name="lastName" nestedValue={setUser} upperValue={user} />
        <InputDiv title="Email" value={user.email} name="email" nestedValue={setUser} upperValue={user} />
        <InputDiv title="Password" value={user.password} name="password" nestedValue={setUser} upperValue={user} />
        {/* Map through the companies and choose one */}
        {/* For now it's just going to be a type in */}
        {/* <InputDiv title="Company" value={user.company} name="company" nestedValue={setUser} upperValue={user} /> */}
        {isOpen && (
        <Modal onClose={() => setIsOpen(false)}>
        <p>{successState}</p>
        <PrimaryButton functionName={() => navigate('/admin')} span="Go Home" />
         </Modal>
        )}
        <SubmitContainer>
        <CreateUserSubmit user={user} setIsOpen={setIsOpen} setSuccessState={setSuccessState}/>
        </SubmitContainer>
        </form>
        </FormContainer>
    );
}
 
export default CreateUser;