import { useNavigate, Link } from "react-router-dom";
import { EachCourse } from "./CourseEntity.styles";
import PrimaryButton from "../../Components/Buttons/primaryButton.component";

// Entity on user home page for showing that the course is incomplete
const IncompleteCourse = ({courses}) => {

    const navigate = useNavigate();

    return ( 
        <div>
            {courses?.courses?.map((c) => {
                if(c?.complete === false){
                    return <EachCourse key={c?.id} className='incomplete boxShadow'>
                    <div className="top">
                    <Link className="title" to={`/course/home/${c.courseId}`}>{c?.courseName}</Link>
                    <span className="italicSpan">This course is incomplete</span>
                    </div>
                    <div className="bottom">
                    <PrimaryButton functionName={() => navigate(`/course/home/${c.courseId}`)} span="Take Course" />
                    </div>
                </EachCourse>
                } else {
                    return null;
                }
            })}
        </div>
     );
}
 
export default IncompleteCourse;