import PrimaryButton from "../../../Components/Buttons/primaryButton.component";
import { formatNum } from "../../../Helpers/formatPhoneNumber";
import { httpEditCompany } from "../../../Hooks/admin.hooks";

// Function for editing a company
const SubmitEditCompany = ({company, setSuccessState, setIsOpen}) => {

    const editCompany = async(e) => {
        e.preventDefault();
        const {phoneMobile, phoneOffice} = company;
        const companyPhone = await formatNum(phoneOffice);
        const mobilephone = await formatNum(phoneMobile);
        const updatedCompany = {
            ...company,
            phoneMobile: mobilephone,
            phoneOffice: companyPhone,
            //updatedAt: Date.now()
        };
        let res = await httpEditCompany(company._id, updatedCompany);
        let data = await res.json();
        if(res.ok){
            setSuccessState('Company has been edited');
            setIsOpen(true)
        } else {
            setSuccessState(data.msg);
            setIsOpen(true)
        }
    }

    return (  
        <>
        <PrimaryButton functionName={editCompany} span="Edit Company" />
        </>
    );
}
 
export default SubmitEditCompany;