import { useEffect, useRef, useState } from "react";
import SecondaryButton from "../../Components/Buttons/secondaryButton.component";

const PdfCreate = ({pdf, setPdf, buttonLabel, displayPdf}) => {
//addNewImage, addNewPhotos
    //const[isOpen, setIsOpen] = useState(false);
    const [display, setDisplay] = useState(false);
    const [error, setError] = useState('');
    //const navigate = useNavigate();
    const cloudinaryRef = useRef();
    const widgetRef = useRef();

    useEffect(() => {
      cloudinaryRef.current = window.cloudinary;
      widgetRef.current = cloudinaryRef.current.createUploadWidget({
        cloudName: 'dteb3vc5h',
        uploadPreset: 'yoda_preset',
        maxFiles: 1,
        
      }, function(error, result){
        if(result.event === 'success'){
          let newPdf = {publicId: result.info.public_id};
          let newPdfURL = result.info.url;
       
        setPdf(newPdf);
        setDisplay(true);
        } else if(error){
          setError('Could not upload image')
        } 
      });
    }, [setPdf]);

    return ( 
        <>
        {display === true && <p>Pdf has been uploaded </p>}
        {displayPdf ?
         <>
       <div style={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
        <SecondaryButton functionName={(e) => {
            e.preventDefault();
            widgetRef.current.open();
          }} span={buttonLabel} />
          {error && <p className="error">{error}</p>}
        </div>
        
        
         </>
      :
      <div style={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
        <SecondaryButton functionName={(e) => {
            e.preventDefault();
            widgetRef.current.open();
          }} span={buttonLabel} />
          {error && <p className="error">{error}</p>}
      </div>
      }
        </>
     );
}
 
export default PdfCreate;