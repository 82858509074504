import { httpGetUser } from "../../../Hooks/users.hooks";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FormContainer, SubmitContainer } from "../../CreateRoutes/CreateForm.styles";
import Modal from "../../../Components/Modal/modal.component";
import EditUserSubmit from "./user-edit-submit.component";
import InputDiv from "../../FormElements/InputText";
import DisableUserButton from "./user-edit-disable.component";
import DeleteUserButton from "./user-edit-delete.component";
import PrimaryButton from "../../../Components/Buttons/primaryButton.component";
import ActivateUserButton from "./user-edit-activate.component";
import Auth from '../../../Helpers/auth';
import { format } from "date-fns";

// Form for editing a user
const EditUser = () => {
    const {id} = useParams();
    const navigate = useNavigate();

    const {data} = Auth.getProfile();
    useEffect(() => {
        if (!Auth.loggedIn()) {
            navigate('/login'); 
        } else if (data.type !== 'Admin'){
            navigate('/unauthorized')
        }
    }, [navigate, data]);

    const [user, setUser] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [successState, setSuccessState] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        const GetUser = async() => {
            let foundUser = await httpGetUser(id);
            if(foundUser){
                setUser(foundUser);
            }
        }
        GetUser();
    }, [id]);
    const formattedCreatedAt = user?.createdAt ? format(new Date(user?.createdAt), 'yyyy-MM-dd HH:mm:ss') : '';
    const formattedUpdatedAt = user?.updatedAt ? format(new Date(user?.updatedAt), 'yyyy-MM-dd HH:mm:ss') : '';
    // firstName, lastName, type, email, active, createdAt, password
    return (
        <FormContainer>
        <h2>Edit User</h2>
        <hr />
        <form>
        <InputDiv title="First Name" value={user?.firstName} name="firstName" nestedValue={setUser} upperValue={user} />
        <InputDiv title="Last Name" value={user?.lastName} name="lastName" nestedValue={setUser} upperValue={user} />
        <InputDiv title="Email" value={user?.email} name="email" nestedValue={setUser} upperValue={user} />
        <InputDiv title="Password" value={password} name="password" setValue={setPassword} />
        <p>Created at: {formattedCreatedAt}</p>
        <p>Last Updated at: {formattedUpdatedAt}</p>
        {isOpen && (
        <Modal onClose={() => setIsOpen(false)}>
        <p>{successState}</p>
        <PrimaryButton functionName={() => navigate('/admin')} span="Go Home" />
         </Modal>
        )}
        <SubmitContainer>
        <div style={{display: 'flex', flexDirection: 'row-reverse', alignContent: 'center', justifyContent: 'space-evenly', width: '100%'}}>
        <EditUserSubmit user={user} password={password} setIsOpen={setIsOpen} setSuccessState={setSuccessState} />
        {user?.active ? 
        <DisableUserButton id={user?._id} setIsOpen={setIsOpen} setSuccessState={setSuccessState} />
        :
        <ActivateUserButton id={user?._id} setIsOpen={setIsOpen} setSuccessState={setSuccessState} />
        }
        <DeleteUserButton id={user?._id} setIsOpen={setIsOpen} setSuccessState={setSuccessState} />
        </div>
        </SubmitContainer>
        </form>
        </FormContainer>
      );
}
 
export default EditUser;